<template>
  <div>
    <b-alert
      v-if="formErrors.non_field_errors !== undefined"
      variant="danger"
      show
    >
      {{ formErrors.non_field_errors[0] }}
    </b-alert>

    <b-form @submit.prevent="submitForm">
      <div class="fields shadow">
        <b-form-group
          label="Email address"
          label-for="email"
          :label-sr-only="true"
        >
          <b-form-input
            id="email"
            v-model="email"
            type="email"
            required
            placeholder="Email address"
          />
        </b-form-group>

        <b-form-group
          label="Password"
          label-for="password"
          :label-sr-only="true"
        >
          <b-form-input
            id="password"
            v-model="password"
            type="password"
            required
            placeholder="Password"
          />
        </b-form-group>
      </div>

      <div class="form-bottom">
        <!-- eslint-disable-next-line vue/singleline-html-element-content-newline -->
        <router-link :to="{name: 'password_reset'}">Forgot Password?</router-link>

        <b-button
          type="submit"
          variant="primary"
          size="lg"
          class="mt-3"
          :disabled="formSubmitting"
        >
          <template v-if="formSubmitting">
            Logging In
            <spinner />
          </template>
          <template v-else>
            Log In
          </template>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner.vue';

export default {
  name: 'Login',
  components: {
    Spinner,
  },
  data() {
    return {
      formSubmitting: false,
      formErrors: {},
      email: '',
      password: '',
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
  },
  created() {
    if (this.userData) {
      this.$router.push({ name: 'main' });
    }
  },
  methods: {
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const postData = {
        username: this.email,
        password: this.password,
      };

      let response;

      try {
        response = await fetch(
          `${process.env.VUE_APP_API_URL}token-auth/`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(postData),
          },
        );
      } catch (e) {} // eslint-disable-line no-empty

      this.formSubmitting = false;

      if (response && [200, 400].includes(response.status)) {
        const responseJson = await response.json();

        if (response.status === 200) {
          this.$store.commit('userData', responseJson);
          this.$router.push(this.$route.query.next || { name: 'main' });
        } else {
          this.formErrors = responseJson;
        }
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.fields {
  position: relative;
  z-index: 1;
}

.form-control {
  padding: 14px 20px;
  height: auto;
  box-shadow: none;
}

.form-group {
  margin-bottom: 0;
}

#email {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

#password {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -0.15rem 1.25rem 0.15rem;

  a,
  .btn {
    white-space: nowrap;
  }

  a {
    font-size: 0.87rem;
    margin-right: 0.9rem;
  }
}

@media (max-width: 385px) {
  .form-bottom .btn {
    padding-right: 10px;
    padding-left: 10px;
  }
}
</style>
